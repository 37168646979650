import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import * as React from 'react';
import { AnimatableBackCloseIcon } from '../icons/AnimatableBackCloseIcon';
import { Button, ButtonFormat, ButtonProps } from './Button';

interface BackCloseButtonProps {
  backState: boolean;
  format?: ButtonFormat;
}

type Props = React.HTMLAttributes<HTMLButtonElement> & ButtonProps & BackCloseButtonProps;

@withStyles(require('./Button.scss'))
export class BackCloseButton extends React.PureComponent<Props> {
  render() {
    const { backState, format, ...rest } = this.props;
    return (
      <Button format={format || 'small'} color="grayInverse" className="Button__back-close-icon" {...rest}>
        <AnimatableBackCloseIcon backState={backState} />
      </Button>
    );
  }
}
