import { ClientApolloProvider } from '@gdp/react-app-plugin-apollo/lib/ClientApolloProvider';
import { ClientHelmetProvider } from '@gdp/react-app-plugin-helmet/lib/ClientHelmetProvider';
import { ClientReduxProvider } from '@gdp/react-app-plugin-redux/lib/ClientReduxProvider';
import { ClientRouterProvider } from '@gdp/react-app-plugin-router/lib/ClientRouterProvider';
import { register as registerServiceWorker } from '@gdp/react-app/lib/helpers/serviceWorker';
import { client } from '@gdp/react-app/lib/platforms/client';
import { ClientStyleProvider } from '@gdp/react-app/lib/platforms/client/providers/ClientStyleProvider';

import '@gdp/react-app/lib/helpers/polyfills';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import Thunk from 'redux-thunk';

import { App, Config, ConfigContextProvider } from 'src/app';
import { appReducer } from 'src/app/reducers/app';
import { defaultOptions, initialData, resolvers } from 'src/platforms/config/graphql';

client<Config>().then(({ compile, config }) => {
  compile(
    <ConfigContextProvider value={config}>
      <App locale={config.locale} />
    </ConfigContextProvider>,
    {
      apollo: new ClientApolloProvider(
        window.__APOLLO_STATE__ || {},
        {
          credentials: 'include',
          uri: config.app.graphqlUrl
        },
        {
          options: {
            lazy: true,
            reconnect: true,
            reconnectionAttempts: config.app.subscriptionReconnectionAttempts
          },
          uri: config.app.wsUrl
        },
        defaultOptions,
        undefined,
        undefined,
        resolvers,
        initialData
      ),
      helmet: new ClientHelmetProvider(),
      redux: new ClientReduxProvider(appReducer, [Thunk], config.platform.environment === 'development'),
      router: new ClientRouterProvider(),
      style: new ClientStyleProvider()
    },
    element => {
      Loadable.preloadReady().then(() => {
        ReactDOM.hydrate(element, document.getElementById('project-root'));
      });
      delete window.__APOLLO_STATE__;
    }
  );

  registerServiceWorker(config);
});
