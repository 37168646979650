import { createMuiTheme, ThemeOptions } from '@material-ui/core';

import { COLOR_ERROR, COLOR_SUCCESS } from 'src/app/components/constants';
import { ThemeCustomizationData } from 'src/app/Customization';

export const getTheme = (customization: ThemeCustomizationData) => {
  const options: ThemeOptions = {
    overrides: {
      MuiAccordion: {
        root: { '&:before': { display: 'none' } },
      },
      MuiAccordionSummary: {
        content: { '&$expanded': { margin: '12px 0' } },
        root: { '&$expanded': { minHeight: '48px' } },
      },
      MuiBackdrop: {
        root: { minWidth: '100%' },
      },
      MuiButton: {
        root: { borderRadius: '12px', textTransform: 'none' },
      },
      MuiButtonGroup: {
        groupedContainedHorizontal: { borderRight: 'none !important' },
        root: { borderRadius: '12px' },
      },
      MuiContainer: {
        root: { paddingLeft: '24px', paddingRight: '24px' },
      },
      MuiDialogActions: {
        root: { padding: '1rem' },
      },
      MuiFab: {
        root: { height: '64px', width: '64px' },
      },
      MuiIconButton: {
        root: { padding: 0 },
      },
      MuiRadio: {
        colorPrimary: { '&$checked': { color: customization.color.primaryDark } },
      },
      MuiSnackbarContent: {
        root: { flexGrow: 0 },
      },
    },
    palette: {
      error: { main: customization.color.error || COLOR_ERROR },
      info: { main: customization.color.info },
      primary: {
        dark: customization.color.primaryDark,
        light: customization.color.primaryLight,
        main: customization.color.primary,
      },
      secondary: {
        dark: customization.color.secondaryDark,
        light: customization.color.secondaryLight,
        main: customization.color.secondary,
      },
      success: { main: customization.color.success || COLOR_SUCCESS },
    },
    typography: {
      body1: { fontSize: '1rem' }, // 16px
      body2: { fontSize: '0.875rem' }, // 14px
      button: { fontSize: '1rem', fontWeight: 'bold', textTransform: 'none' }, // 16px
      caption: { fontSize: '0.75rem' }, // 12px
      fontFamily: ['Gotham SSm A', 'Gotham SSm B', 'sans-serif'].join(','),
      h1: { fontSize: '3.375rem', fontWeight: 'bold' }, // 54px
      h2: { fontSize: '2.25rem', fontWeight: 'bold' }, // 36px
      h3: { fontSize: '1.5rem', fontWeight: 'bold' }, // 24px
      h4: { fontSize: '1rem', fontWeight: 'bold' }, // 16px
      h5: { fontSize: '0.5rem' }, // 8px
      subtitle1: { fontSize: '1rem', fontWeight: 'bold' }, // 16px
      subtitle2: { fontSize: '0.875rem', fontWeight: 'bold' }, // 14px
    },
  };

  // somehow default text color data will be replaced even if the value is undefined, not like primary or secondary color
  if (customization.color.textPrimary) {
    options.palette!.text!.primary = customization.color.textPrimary;
  }
  if (customization.color.textSecondary) {
    options.palette!.text!.secondary = customization.color.textSecondary;
  }

  return createMuiTheme(options);
};
