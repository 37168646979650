import gql from 'graphql-tag';

export const GET_SELECTED_DELIVERY_LOCATION = gql`
  query GetSelectedDeliveryLocation {
    selectedDeliveryLocation @client
  }
`;

export interface FetchSelectedDeliveryLocationResult {
  selectedDeliveryLocation: DeliveryLocationCache;
}

export const SET_SELECTED_DELIVERY_LOCATION = gql`
  mutation SetSelectedDeliveryLocation(
    $detail: String
    $address: String!
    $latitude: Float!
    $longitude: Float!
    $contactPersonName: String!
    $contactPersonPhoneNo: String!
    $isAddressExist: Boolean = false
  ) {
    setSelectedDeliveryLocation(
      detail: $detail
      address: $address
      latitude: $latitude
      longitude: $longitude
      contactPersonName: $contactPersonName
      contactPersonPhoneNo: $contactPersonPhoneNo
      isAddressExist: $isAddressExist
    ) @client
  }
`;

export interface SelectedDeliveryLocationVariables {
  detail?: string;
  address: string;
  latitude: number;
  longitude: number;
  contactPersonName: string;
  contactPersonPhoneNo: string;
  isAddressExist?: boolean;
}

export interface DeliveryLocationCache {
  __typename: 'DeliveryLocation';
  detail?: string;
  address: string;
  latitude: number;
  longitude: number;
  contactPersonName: string;
  contactPersonPhoneNo: string;
  isAddressExist?: boolean;
}
