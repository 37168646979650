import gql from 'graphql-tag';

export const GET_SELECTED_ADDRESS = gql`
  query GetSelectedAddress {
    selectedAddress @client
  }
`;

export interface FetchSelectedAddressResult {
  selectedAddress: AddressCache;
}

export const SET_SELECTED_ADDRESS = gql`
  mutation SetSelectedAddress(
    $name: String
    $address: String!
    $addressDetail: String
    $latitude: Float!
    $longitude: Float!
    $contactPersonName: String!
    $contactPersonPhoneNo: String!
  ) {
    setSelectedAddress(
      name: $name
      address: $address
      addressDetail: $addressDetail
      latitude: $latitude
      longitude: $longitude
      contactPersonName: $contactPersonName
      contactPersonPhoneNo: $contactPersonPhoneNo
    ) @client
  }
`;

export interface SelectedAddressVariables {
  name: string;
  address: string;
  addressDetail: string;
  latitude: number;
  longitude: number;
  contactPersonName: string;
  contactPersonPhoneNo: string;
}

export interface AddressCache {
  __typename: 'Address';
  name?: string;
  addressDetail: string;
  address: string;
  latitude: number;
  longitude: number;
  contactPersonName: string;
  contactPersonPhoneNo: string;
}
