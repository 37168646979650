import gql from 'graphql-tag';

export const GET_PREVIOUS_PATH = gql`
  query GetPreviousPath {
    prevPath @client
  }
`;

export interface FetchPreviousPathResult {
  prevPath: string;
}

export const SET_PREVIOUS_PATH = gql`
  mutation SetPreviousPath($path: String!) {
    setPreviousPath(path: $path) @client
  }
`;

export interface PreviousPathVariables {
  path: string;
}
