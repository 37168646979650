import { LOCALE_EN, LOCALE_ID } from '@gdp/react-app/lib/components/LocaleProvider';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { IntlProvider } from 'react-intl';
import momentLocaliser from 'react-widgets-moment';

import { Routes } from './components/Routes';
import { ASSETS, CustomizationContext, THEME } from './Customization';
import { intlMessagesEn } from './locale/en';
import { intlMessagesId } from './locale/id';

momentLocaliser();

const messages = { [LOCALE_EN]: intlMessagesEn, [LOCALE_ID]: intlMessagesId };

interface AppProps {
  locale: string;
}

export const App: React.FC<AppProps> = ({ locale }) => {
  React.useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentElement) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  return (
    <IntlProvider messages={messages[locale]} locale={locale}>
      <ThemeProvider theme={THEME}>
        <CustomizationContext.Provider value={{ assets: ASSETS, theme: THEME }}>
          <Routes />
        </CustomizationContext.Provider>
      </ThemeProvider>
    </IntlProvider>
  );
};
