import * as React from 'react';

import { LOCALE_EN } from '@gdp/react-app/lib/components/LocaleProvider';
import { Config as BaseConfig } from '@gdp/react-app/lib/models/config';

export interface Config extends BaseConfig {
  app: {
    espayApiKey: string;
    espayBankCode: string;
    espayBankProduct: string;
    espayEmbedKit: string;
    espayMobileBankCode: string;
    espayMobileBankProduct: string;
    firebase: {
      apiKey: string;
      appId: string;
      authDomain: string;
      databaseURL: string;
      measurementId: string;
      messagingSenderId: string;
      projectId: string;
      storageBucket: string;
    };
    googleMapsKey: string;
    googleTagManagerId?: string;
    graphqlUrl: string;
    appUrl: string;
    dealsPath: string;
    midtransEmbedSrcUrl: string;
    midtransEnv: string;
    midtransMachineCreditCardClientKey: string;
    midtransOutletCreditCardClientKey: string;
    reCaptcha: {
      siteKey: string;
    };
    wsUrl: string;
    subscriptionReconnectionAttempts: number;
    applyStrictTransportSecurityHeader: string;
    jumpstartContactUrl: string;
    jumpstartReferralUrl: string;
  };
  locale: string;
}

const { Provider, Consumer } = React.createContext<Config>({
  app: {
    appUrl: process.env.APP_URL || 'https://localhost:3101',
    applyStrictTransportSecurityHeader: 'false',
    dealsPath: process.env.DEALS_PATH || '/deals',
    espayApiKey: process.env.ESPAY_API_KEY || 'db025328b6a678721813ccbc775cacdf',
    espayBankCode: process.env.ESPAY_BANK_CODE || '075',
    espayBankProduct: process.env.ESPAY_BANK_PRODUCT || 'JENIUSIB',
    espayEmbedKit: process.env.ESPAY_EMBED_KIT || 'https://sandbox-kit.espay.id/public/signature/js',
    espayMobileBankCode: process.env.ESPAY_MOBILE_BANK_CODE || '075',
    espayMobileBankProduct: process.env.ESPAY_MOBILE_BANK_PRODUCT || 'JENIUSIB',
    firebase: {
      apiKey: process.env.FIREBASE_API_KEY || '',
      appId: process.env.FIREBASE_APP_ID || '',
      authDomain: process.env.FIREBASE_AUTH_DOMAIN || '',
      databaseURL: process.env.FIREBASE_DATABASE_URL || '',
      measurementId: process.env.FIREBASE_MEASUREMENT_ID || '',
      messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || '',
      projectId: process.env.FIREBASE_PROJECT_ID || '',
      storageBucket: process.env.FIREBASE_STORAGE_BUCKET || '',
    },
    googleMapsKey: process.env.REACT_APP_GOOGLE_KEY || '',
    graphqlUrl: (process.env.API_URL || 'http://localhost:3100') + (process.env.GRAPHQL_URL || '/api/graphql'),
    jumpstartContactUrl: process.env.JUMPSTART_CONTACT_URL || 'https://wa.me/+6281384319775',
    jumpstartReferralUrl: process.env.JUMPSTART_REFERRAL_URL || 'https://staging-portal.myjumpstartcoffee.com',
    midtransEmbedSrcUrl:
      process.env.MIDTRANS_EMBED_SRC_URL || 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js',
    midtransEnv: process.env.MIDTRANS_ENV || 'sandbox',
    midtransMachineCreditCardClientKey:
      process.env.MIDTRANS_MACHINE_CREDIT_CARD_CLIENT_KEY || 'SB-Mid-client-G0GW7KDlnbaHLBnO',
    midtransOutletCreditCardClientKey:
      process.env.MIDTRANS_OUTLET_CREDIT_CARD_CLIENT_KEY || 'SB-Mid-client-t0XoF4UBWrpmhadA',
    reCaptcha: {
      siteKey: process.env.RECAPTCHA_SITE_KEY || '',
    },
    subscriptionReconnectionAttempts: Number(process.env.SUBSCRIPTION_RECONNECTION_ATTEMPTS) || 3,
    wsUrl: (process.env.WS_URL || 'ws://localhost:3100') + (process.env.WS_ENDPOINT || '/graphql'),
  },
  locale: LOCALE_EN,
  platform: {
    buildMode: 'development',
    buildNumber: 'development',
    csrUrl: 'http://localhost:3130',
    environment: 'development',
    publicUrl: 'https://localhost:3101',
    ssrUrl: 'http://localhost:3180',
  },
});

export const ConfigContextProvider = Provider;
export const ConfigContextConsumer = Consumer;
export interface ConfigProps {
  config: Config;
}
