export const intlMessagesEn = {
  'project.title': 'Jumpstart Portal Frontend',

  'general.action.close': 'Close',
  'general.action.pay': 'Pay',
  'general.action.seeDetail': 'See Detail',
  'general.action.trackOrder': 'Track Order',
  'general.label.expiredAt': 'Expired at',
  'general.label.missedAt': 'Valid until',
  'general.label.orderSummary': 'Order Summary',
  'general.label.paidAt': 'Paid at',
  'general.label.paymentMethod': 'Payment Method',
  'general.label.paymentSuccess': 'Payment Success',
  'general.label.paymentSummary': 'Payment Summary',
  'general.label.reward': 'JS Reward',
  'general.label.sugar.sachet': '* 1 level = 1/2 sachet sugar (4 gram)',
  'general.label.totalItems': '{totalItems, plural, one {# item} other {# items}}',
  'general.separator.dot': ' • ',

  'page.aboutUs': 'About Us',
  'page.accountSetting': 'Account Setting',
  'page.address': 'Address',
  'page.address.add': 'New Address',
  'page.address.edit': 'Address Details',
  'page.address.search': 'New Address',
  'page.checkout': 'Confirm Order',
  'page.contact': 'Contact Us',
  'page.creditCard': 'Debit/Credit Card',
  'page.deal': 'Deals',
  'page.deals': 'Deals',
  'page.delivery.search': 'Search Location',
  'page.delivery.search.address': 'Search Address',
  'page.delivery.search.detail': 'Location Detail',
  'page.faq': 'FAQ',
  'page.home': 'Jumpstart',
  'page.machineTypeDisplay': 'Select machine type',
  'page.membership': 'Membership Level',
  'page.menu': 'Outlet Menu',
  'page.notFound': 'Page not found.',
  'page.notification': 'Notification',
  'page.notification.detail': 'Notification Detail',
  'page.order': 'Order',
  'page.order.detail': 'Order Detail',
  'page.order.history': 'Order History',
  'page.order.ongoing': 'Ongoing Order',
  'page.order.track': 'Track Order',
  'page.orderDestination': 'Order from',
  'page.orderDestination.machine.description': 'Order your favorite drinks and snacks from our Smart Machine',
  'page.orderDestination.machine.info': 'Available in 600+ locations across Jabodetabek area',
  'page.orderDestination.machine.title': 'Coffee & Snack Machine',
  'page.orderDestination.outlet.description': 'Pick up & Delivery order from our outlets across jabodetabek area',
  'page.orderDestination.outlet.info': 'Available in 16+ outlets across Jabodetabek area',
  'page.orderDestination.outlet.title': 'Outlet',
  'page.otherMenu': 'Account',
  'page.paymentStatus': 'Payment Status',
  'page.product': '{name} Menu',
  'page.product.detail': 'Menu Detail',
  'page.profile': 'My Profile',
  'page.promo': 'Deals',
  'page.promo.detail': 'Promo Detail',
  'page.scanQr': 'Scan QR',
  'page.scanQrCode': 'Scan QR Code',
  'page.storeLocation': 'Store Location',
  'page.termsAndConditions': 'Terms and Conditions',
  'page.voucher': 'My Vouchers',
  'page.voucher.all': 'All Vouchers',
  'page.voucher.detail': 'Voucher Detail',
  'page.voucher.thirdParty': 'Deals',
  'page.vouchers': 'Vouchers',

  'account.label.about': 'About Jumpstart',
  'account.label.address': 'My Address',
  'account.label.contact': 'Contact Us',
  'account.label.faq': 'FAQ',
  'account.label.login': 'Log In',
  'account.label.logout': 'Log Out',
  'account.label.profile': 'My Profile',
  'account.label.scan.qr': 'Scan QR Code',
  'account.label.voucher.all': 'All Vouchers',

  'address.action.add': 'Add new address',
  'address.action.save': 'Save address',
  'address.error.location.notFound': 'Cannot find any store in this location',
  'address.form.label.address': 'Address',
  'address.form.label.detail': 'Address Detail',
  'address.form.label.name': 'Contact Person Name',
  'address.form.label.phone': 'Phone Number',
  'address.form.placeholder.detail': 'ex. Belleza',
  'address.form.placeholder.name': 'ex. John Doe',
  'address.form.placeholder.phone': '812 487 1234',
  'address.label.edit': 'Edit',
  'address.label.empty.message': 'No Address',
  'address.label.empty.title': 'Oops! you have no address yet...',
  'address.label.recentLocation': 'Recent Location',
  'address.label.search': 'Search address',
  'address.label.selectmap': 'Select via map',

  'checkout.action.browseMenu': 'Browse Menu',
  'checkout.action.submit': 'Place Order',
  'checkout.cart.info.empty': 'Your cart is empty',
  'checkout.error.deliveryFee.content': "Sorry it's on us, please try again later",
  'checkout.error.deliveryFee.header': 'Cannot get delivery fee',
  'checkout.error.farDestination.content': 'Sorry, please try to find a closer place',
  'checkout.error.farDestination.header': 'The destination is too far',
  'checkout.error.insufficientPaidAmount.content':
    'The minimum payout is Rp10.000. Please select different payment method',
  'checkout.error.insufficientPaidAmount.header': "You haven't reached the minimum payout yet",
  'checkout.error.invalidVoucher.content': "Please check the voucher's terms and conditions",
  'checkout.error.invalidVoucher.header': 'Cannot apply this voucher',
  'checkout.error.itemUnavailable.content': 'Sorry, please update your cart',
  'checkout.error.itemUnavailable.header': "Item(s) aren't available at the outlet",
  'checkout.error.storeUnavailable.content': 'You can still order by changing the service time and set the time',
  'checkout.error.storeUnavailable.header': 'The store is closed',
  'checkout.label.item.add': '+ add more',
  'checkout.label.order.summary': 'Order Summary',
  'checkout.label.paymentDetail': 'Payment Detail',
  'checkout.label.paymentDetail.delivery': 'Delivery fee',
  'checkout.label.paymentDetail.delivery.discount': 'Discount delivery',
  'checkout.label.paymentDetail.discount': 'Discount items',
  'checkout.label.paymentDetail.order': 'Order items',
  'checkout.label.paymentDetail.order.discount': 'Discount items',
  'checkout.label.paymentDetail.total': 'Total',
  'checkout.label.voucher.remove': 'Remove',
  'checkout.placeholder.delivery.location': 'Deliver your order to',
  'checkout.placeholder.pickup.location': 'Pickup your order at',

  'creditCard.error.content': 'Please make sure that all fields are filled out correctly and try again',
  'creditCard.error.header': 'Error when processing your payment',

  'deal.label.button': 'Order Now',
  'deal.label.enterPromo': 'Enter promo code here',
  'deal.label.locationType.machine': 'Machine',
  'deal.label.locationType.outlet': 'Outlet',
  'deal.label.promoHeader': "PROMO YOU CAN'T RESIST",
  'deal.label.tnc': 'Terms & Conditions',
  'deal.label.validUntil': 'Valid until ',
  'deal.label.voucherHeader': 'CLAIM MORE VOUCHER NOW',

  'delivery.action.choose': 'Confirm Location',
  'delivery.action.save': 'Save Location',

  'loyaltyPoint.label.point': 'pts',

  'payment.header.expired': 'Your payment has expired',
  'payment.header.expired.description': 'You can place the same order in our menu list',
  'payment.header.successful': 'HOORAAAAY!',
  'payment.header.successful.description1': 'We got your order.',
  'payment.header.successful.description2': 'Get ready to enjoy your Jumpstart Coffee!',
  'payment.header.waiting': 'Waiting for your payment',
  'payment.order.action.backToHome': 'Back to Home',
  'payment.order.action.backToMenu': 'Back to Menu',
  'payment.order.action.pay': 'Pay',
  'payment.order.action.seeQrCode': 'See QR Code',
  'payment.order.label.history': 'Order History',
  'payment.order.label.order': 'Ongoing Order',
  'payment.order.label.time.delivery': 'Delivery time',
  'payment.order.label.time.pickUp': 'Pick up time',
  'payment.order.label.transaction.amount': 'Paid amount',
  'payment.order.label.transaction.number': 'Transaction number',
  'payment.subheader.successful': 'Thank you! You have completed your order',

  'navigation.label.home': 'Home',
  'navigation.label.menu': 'Menu',
  'navigation.label.order': 'Order',
  'navigation.label.otherMenu': 'Account',
  'navigation.label.promo': 'Deals',
  'navigation.label.voucher': 'Voucher',

  'cart.label.add': 'Add to Cart',
  'cart.label.edit': 'Edit Item',
  'cart.label.estimatedPrice': 'Estimated price',
  'cart.label.items': '{totalItems, plural, one {# item} other {# items}}',
  'cart.label.loading': 'Loading cart items...',
  'cart.label.noData': 'Cart is not available right now.',
  'cart.label.remove': 'Remove Item',

  'home.guest.continue': 'Continue as Guest',
  'home.menu.cta': 'See Our Menu',
  'home.notification.action.mark': 'Mark all as read',
  'home.notification.action.show': 'Show all',
  'home.notification.title': 'Notifications',
  'home.ongoingOrder': 'Ongoing Order',
  'home.order.cta': 'Order Here',
  'home.register.cta': 'Register/Login',

  'loyaltyPoint.earned.caption': 'You get cashback from this transaction',

  'menu.detail.addToppings': 'Add Toppings',
  'menu.detail.pageTitle': 'Menu Detail',

  'notification.label.empty.message': 'Notification is empty',
  'notification.label.empty.title': 'We will notify you when good news is out',

  'promo.action.register': 'Register',
  'promo.action.seeMore': 'See More',
  'promo.label.empty.message': 'Promo is empty',
  'promo.label.empty.title': 'We will notify you when our promo is out',
  'promo.label.validUntil': 'Valid until ',

  'thirdParty.label.empty.message': 'Deals is empty',
  'thirdParty.label.empty.title': 'There is currently no deals',

  'voucher.action.claim': 'Claim',
  'voucher.action.claimed': 'Claimed',
  'voucher.action.see': 'See My Vouchers',
  'voucher.action.see.all': 'See All Vouchers',
  'voucher.action.use': 'Use',
  'voucher.action.useNow': 'Use Now',
  'voucher.label.empty.message': 'Voucher is empty',
  'voucher.label.empty.title': 'Buy more drink to get free vouchers',
  'voucher.label.search': 'Got a promo code? Enter here',
  'voucher.label.tnc': 'Terms & Conditions',
  'voucher.label.validUntil': 'Valid until ',
  'voucher.tabs.allVouchers': 'All Vouchers',
  'voucher.tabs.myVouchers': 'My Vouchers',
  'voucher.tabs.promo': 'Promo',
  'voucher.tabs.voucher': 'Voucher',

  'order.history.label.empty.message': 'Seems you never order before :(',
  'order.history.label.empty.title': 'History empty',
  'order.history.tabs.history': 'History',
  'order.history.tabs.onGoing': 'On Going',
  'order.onGoing.action.seeDetails': 'See details',
  'order.onGoing.detail.label.expiredAt': 'Expired At',
  'order.onGoing.detail.label.paymentMethod': 'Payment Method',
  'order.onGoing.detail.label.status': 'Status',
  'order.onGoing.detail.label.tracking': 'Tracking',
  'order.onGoing.label.empty.message': 'Oops! you have no order yet...',
  'order.onGoing.label.empty.title': 'Order empty',

  'reward.label.title': 'Rewards by {companyName}',

  'rewardPayment.detail.amount': 'Balance Rp. {amount}',
  'rewardPayment.detail.machineLocation': 'Machine Location',
  'rewardPayment.detail.order': 'Order',
  'rewardPayment.label.content': 'Scan QR to pay in Jumpstart smart machine',

  'rewardPaymentSuccess.label.footer': 'Automatically direct in {redirectCountdown} seconds',

  'store.detail.separator': ' • ',
  'store.notFound': 'Cannot find any store',

  'pushNotification.action.enable': 'Enable Notification',
  'pushNotification.action.later': 'Later',
  'pushNotification.modal.title': 'Enable notification to track your order and stay updated for special offers.',

  'qrInstruction.body': 'Scan QR code on your cups here to get more promo!',
  'qrInstruction.body.error': 'Please try to scan the QR again.',
  'qrInstruction.header': 'Scan QR Code',
  'qrInstruction.header.error': 'Unrecognized QR Code',
  'qrInstruction.scanAgain': 'Scan Again',

  'form.creditCard.label.amount': 'Total',
  'form.creditCard.label.cardNumber': 'Card Number',
  'form.creditCard.label.cvv': 'Security Code (CVV)',
  'form.creditCard.label.expirationDate': 'Expiration Date (MM/YY)',

  'form.login.action.next': '→',
  'form.login.action.otp.forgot': 'Forgot PIN?',
  'form.login.action.otp.resend': 'Resend OTP',
  'form.login.error.invalidReferralCode.content': 'Please double check the referral code or just simply remove it',
  'form.login.error.invalidReferralCode.header': 'The referral code is not valid',
  'form.login.error.otp.api': 'Can’t contact server for validation.',
  'form.login.error.otp.incorrect': 'Incorrect OTP code.',
  'form.login.error.pin.incorrect': 'Incorrect PIN entered.',
  'form.login.label.dateOfBirth': 'Date of birth',
  'form.login.label.email': 'Email',
  'form.login.label.gender': 'Gender (optional)',
  'form.login.label.gender.female': 'Female',
  'form.login.label.gender.male': 'Male',
  'form.login.label.name': 'Full name',
  'form.login.label.otp': 'Verification',
  'form.login.label.otp.resend': 'Haven’t received any?',
  'form.login.label.otp.resend.cooldown.timer': 'Resend OTP in',
  'form.login.label.otp.resend.cooldown.timer.unit': 'second',
  'form.login.label.otp.resend.successful': 'OTP has been resent.',
  'form.login.label.otp.send.successful': 'OTP has been sent.',
  'form.login.label.otp.tips': 'Enter the 6-digit sent to',
  'form.login.label.phoneNo': 'Enter your phone number',
  'form.login.label.phoneNo.tips': 'Log in or sign up.',
  'form.login.label.pin': 'Enter PIN',
  'form.login.label.pin.new': 'Create new PIN',
  'form.login.label.pin.new.tips': 'Enter your 6-digit PIN.',
  'form.login.label.pin.tips': 'Type in your PIN to log in.',
  'form.login.label.referralCode': 'Referral code (optional)',
  'form.login.register.termsAndConditions': 'Terms and Conditions',
  'form.login.register.termsAndConditions.intro': 'By signing up, you agree to our',

  'form.profile.action.submit': 'Save',
  'form.profile.label.birthDate': 'Birth date',
  'form.profile.label.dateOfBirth': 'Date of birth',
  'form.profile.label.email': 'Email address',
  'form.profile.label.gender': 'Gender',
  'form.profile.label.gender.female': 'Female',
  'form.profile.label.gender.male': 'Male',
  'form.profile.label.modal.button.logout.cancel': 'Nope, bring me back',
  'form.profile.label.modal.button.logout.submit': "Sorry, I'll log out",
  'form.profile.label.modal.button.save.cancel': 'Nope',
  'form.profile.label.modal.button.save.submit': 'Yes',
  'form.profile.label.modal.title.logout': 'Are you sure want to log out?',
  'form.profile.label.modal.title.save': 'Do you want to save your changes?',
  'form.profile.label.name': 'Full name',
  'form.profile.label.phoneNo': 'Phone number',
  'form.profile.label.referral': 'Referral code',
  'form.profile.label.referral.copied': 'Referral code copied to your clipboard.',
  'form.profile.label.referral.tips': 'Share your referral code below!',
  'form.profile.label.snackbar.button': 'OK',
  'form.profile.label.snackbar.message': 'Changes saved successfully',

  'form.order.action.cart.add': 'Add to Cart',
  'form.order.action.cart.checkout': 'Checkout',
  'form.order.action.cart.edit': 'Edit',
  'form.order.action.cart.order': 'Order',
  'form.order.label.empty.message': 'Fill in your cart by ordering your favourite drink',
  'form.order.label.empty.title': 'Empty Cart',
  'form.order.label.menu.customization.unavailable':
    'Some customizations are currently not available. Please edit this item’s customization.',
  'form.order.label.menu.unavailable': 'This menu is currently not available. Please remove this item from you cart.',
  'form.order.label.notes': 'Example: extra ice please',
  'form.order.label.notes.add': 'Add notes',
  'form.order.label.total': 'Total',

  'form.rewardPayment.label.modal.body.error': 'Invalid QR detected',
  'form.rewardPayment.label.modal.body.error.expired': 'QR is expired',
  'form.rewardPayment.label.modal.body.error.insufficientAmount': 'Insufficient amount',
  'form.rewardPayment.label.modal.body.error.insufficientUsage': `You've reached usage limit`,
  'form.rewardPayment.label.modal.body.error.invalidMachine': `JS reward can't be used on this machine`,
  'form.rewardPayment.label.modal.body.error.invalidProduct': `JS reward can't be used for this item`,
  'form.rewardPayment.label.modal.body.error.notOnSchedule': `Currently JS reward can't be used. Please try again next time`,
  'form.rewardPayment.label.modal.body.error.used': `QR is already used`,
  'form.rewardPayment.label.modal.title.error': 'Unable to Make the Payment',

  'modal.delete.label.cancel': 'Cancel',
  'modal.delete.label.confirm': 'Yes, remove',
  'modal.delete.label.description': 'This item will be deleted from your ',
  'modal.delete.label.title': 'Remove item?',
  'storelocation.label.search': 'Search Store',

  'not.found.button': 'Back to homepage',
  'not.found.caption': 'Sorry, the page you requested could not be found',
  'not.found.caption.header': 'Oops.. 404 Not Found',

  'membership.achieve': 'Buy {cups, plural, one {# cup} other {# cups}} to achieve next level',
  'membership.action.order': 'Order to Maintain Your Level',
  'membership.completed': 'Nikmati keseruanmu dengan Jumpstart!',
  'membership.retain': 'Buy {cups, plural, one {# cup} other {# cups}} to retain ',
  'membership.updated': 'You will be updated to',

  'validator.digit': 'This field must be number',
  'validator.email': 'Please enter a valid email.',
  'validator.fullPhoneNo': 'Enter a valid phone number. If you’re using landline, kindly provide the area code.',
  'validator.isRobot': 'You are not allowed to access this site',
  'validator.limitOtp': 'You’ve exceeded the limit of OTP requests. Please try again tomorrow',
  'validator.phoneNo': 'Enter a valid phone number.',
  'validator.required': 'This field is required.',
  'validator.sixDigit': 'PIN must be 6-digit.',
  'validator.sixDigitOTP': 'OTP must be 6-digit',
  'validator.tooManyAttempts': 'Too many attempts. Please try again later',

  'voucher.error.claimed': `You've already claimed the voucher`,
  'voucher.error.general': 'Something wrong when claiming the voucher, please try again later',
  'voucher.error.quantity': 'Sorry, there’s no voucher left',
  'voucher.error.unclaimable': 'Sorry but you cannot claim the voucher, please see the terms and conditions',
  'voucher.success.claim': `Congrats, you've just got a new voucher: {codeName}`,

  'placeholder.cardNumber': 'xxxx - xxxx - xxxx - xxxx',
  'placeholder.cvv': 'Example: 123',
  'placeholder.expirationDate': 'MM/YY',
  'placeholder.null': ' ',
};
