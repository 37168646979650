import gql from 'graphql-tag';
import { Hub } from 'src/app/pages/StoreLocation/StoreLocation.queries';

export const GET_SELECTED_STORE = gql`
  query GetSelectedStore {
    selectedStore @client
  }
`;

export interface FetchSelectedStoreResult {
  selectedStore: Hub;
}

export const SET_SELECTED_STORE = gql`
  mutation SetSelectedStore($id: String!, $name: String!, $address: String!, $latitude: Float!, $longitude: Float!) {
    setSelectedStore(id: $id, name: $name, address: $address, latitude: $latitude, longitude: $longitude) @client
  }
`;

export interface SelectedStoreVariables {
  id: string;
  name: string;
  address: string;
  latitude: number;
  longitude: number;
}
