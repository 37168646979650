import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';

interface AnimatableBackCloseIconProps {
  backState: boolean;
}

@withStyles(require('./AnimatableBackCloseIcon.scss'))
export class AnimatableBackCloseIcon extends React.PureComponent<AnimatableBackCloseIconProps> {
  render() {
    const animatableBackCloseIconClass = classNames({
      'AnimatableBackCloseIcon': true,
      'AnimatableBackCloseIcon--back': this.props.backState
    });
    return (
      <span className="AnimatableBackCloseIcon__container">
        <div className={animatableBackCloseIconClass}>
          <div className="AnimatableBackCloseIcon__figure AnimatableBackCloseIcon__figure--one"/>
          <div className="AnimatableBackCloseIcon__figure AnimatableBackCloseIcon__figure--two"/>
        </div>
      </span>
    );
  }
}
