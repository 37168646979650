export interface Route {
  path: string;
  requiredAuthority?: string;
}

export const HOME: Route = { path: '/' };
export const LOGIN: Route = { path: '/login' };

export const VOUCHER: Route = { path: '/voucher' };
export const VOUCHER_ALL: Route = { path: `${VOUCHER.path}/all` };
export const VOUCHER_ALL_DETAIL: Route = { path: `${VOUCHER_ALL.path}/:voucherCodename` };
export const VOUCHER_ME: Route = { path: `${VOUCHER.path}/me` };
export const VOUCHER_ME_DETAIL: Route = { path: `${VOUCHER_ME.path}/voucher/me/:id` };
export const PROMO: Route = { path: '/promo' };
export const PROMO_DETAIL: Route = { path: '/promo/:promoId' };

export const PRODUCT_VOUCHER: Route = { path: '/voucher/product' };
export const PRODUCT_VOUCHER_ALL: Route = { path: `${PRODUCT_VOUCHER.path}/all` };
export const PRODUCT_VOUCHER_ALL_DETAIL: Route = { path: `${PRODUCT_VOUCHER_ALL.path}/:voucherCodeName` };
export const PRODUCT_VOUCHER_ME_DETAIL: Route = { path: `${PRODUCT_VOUCHER.path}/me/:id` };
export const PRODUCT_PROMO_DETAIL: Route = { path: '/promo/product/:promoId' };

export const ORDER_DESTINATION: Route = { path: '/order-from' };

export const MACHINE_TYPE_DISPLAY: Route = { path: '/machine' };
export const MACHINE_MENU: Route = { path: '/machine/:machineId/menu' };
export const MACHINE_MENU_DETAIL: Route = { path: `${MACHINE_MENU.path}/:productSlugName/:productId` };
export const MACHINE_MENU_EDIT: Route = { path: `${MACHINE_MENU_DETAIL.path}/:itemId` };

export const MENU: Route = { path: '/menu' };
export const HUB_MENU: Route = { path: '/outlet/menu' };
export const HUB_MENU_DETAIL: Route = { path: '/outlet/menu/:menuSlugName/:menuId' };
export const HUB_MENU_EDIT: Route = { path: `/outlet/menu/:menuSlugName/:menuId/:itemId` };

export const NOTIFICATION: Route = { path: '/notification' };
export const NOTIFICATION_DETAIL: Route = { path: '/notification/:notificationId' };

export const ORDER_HISTORY: Route = { path: '/order/history' };
export const ONGOING_ORDER: Route = { path: '/order/ongoing' };
export const ORDER_TRACKING: Route = { path: '/order/:orderId/tracking' };
export const MACHINE_ORDER_DETAIL: Route = { path: '/order/machine/:orderNo' };
export const HUB_ORDER_DETAIL: Route = { path: '/order/outlet/:orderId' };

export const CHECKOUT: Route = { path: '/checkout' };
export const HUB_CHECKOUT: Route = { path: '/outlet/checkout' };
export const MACHINE_CHECKOUT: Route = { path: '/machine/:machineId/checkout' };

export const HUB_PAYMENT: Route = { path: '/payment/hub' };
export const MACHINE_PAYMENT: Route = { path: '/payment/machine' };

export const STORE_LOCATION: Route = { path: '/storelocation' };

export const DELIVERY_LOCATION: Route = { path: '/deliverylocation' };
export const DELIVERY_LOCATION_EDIT: Route = { path: '/deliverylocation/edit/:addressId' };
export const DELIVERY_LOCATION_SEARCH: Route = { path: '/deliverylocation/search' };
export const DELIVERY_LOCATION_SEARCH_DETAIL: Route = { path: '/deliverylocation/search/detail' };

export const ACCOUNT_SETTING: Route = { path: '/account-setting' };

export const SCAN_QR: Route = { path: '/scanqr' };

export const FAQ: Route = { path: '/faq' };

export const ABOUT_US: Route = { path: '/about-us' };

export const CONTACT: Route = { path: '/contact' };

export const OTHER_MENU: Route = { path: '/other' };

export const ESPAY: Route = { path: '/espay' };

export const DEAL: Route = { path: '/deals/:pathName' };

export const MEMBERSHIP: Route = { path: '/membership' };
export const ALL_MEMBERSHIPS: Route = { path: '/membership/all' };

export const REWARD_ORDER_DETAIL: Route = { path: '/order/reward/:orderId' };
export const REWARD_PAYMENT: Route = { path: '/reward-payment' };
