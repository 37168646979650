import * as React from 'react';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import classNames from 'classnames';

export type ButtonFormat = 'small' | 'medium' | 'large' | 'large-disabled' | 'small-rounded';

export interface ButtonProps {
  format?: ButtonFormat;
  color?: 'grayInverse' | 'positive' | 'transparent' | 'blackInverse' | 'darkGrayInverse';
  shadow?: 'standard';
  customRef?: any;
  disabled?: any;
}

export const ButtonClassnames = (color?: string, format?: string, shadow?: string) => {
  return classNames({
    Button: true,
    'Button--color-blackInverse': color === 'blackInverse',
    'Button--color-darkGrayInverse': color === 'darkGrayInverse',
    'Button--color-grayInverse': color === 'grayInverse',
    'Button--color-positive': color === 'positive',
    'Button--color-transparent': color === 'transparent',
    'Button--format-large': format === 'large',
    'Button--format-large-disabled': format === 'large-disabled',
    'Button--format-medium': format === 'medium',
    'Button--format-small': format === 'small',
    'Button--format-small-rounded': format === 'small-rounded',
    'Button--shadow-standard': shadow === 'standard'
  });
};

@withStyles(require('./Button.scss'))
export class Button extends React.PureComponent<React.HTMLAttributes<HTMLButtonElement> & ButtonProps> {
  render() {
    const { children, color, format, shadow, customRef, className, disabled, ...restOfProps } = this.props;
    return (
      <button
        {...restOfProps}
        disabled={disabled}
        type="button"
        className={ButtonClassnames(color, format, shadow) + ' ' + className}
        ref={customRef}
      >
        {children}
      </button>
    );
  }
}
