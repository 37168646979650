import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { RouteComponentProps } from 'react-router';
import { Button } from 'src/app/components/generics';
import { HOME } from 'src/app/constants/route';
import { HttpStatus, HttpStatusContext } from 'src/app/helpers/http';

type NotFoundProps = RouteComponentProps;

const imgCupEmpty = (
  <img
    src="/assets/order-empty-1.png"
    srcSet="/assets/order-empty-1@2x.png 2x,
    /assets/order-empty-1@3x.png 3x"
    alt="Empty cup"
  />
);

@withStyles(require('./NotFound.scss'))
export class NotFound extends React.Component<NotFoundProps> {

  renderCup() {
    return (
      <div className="NotFound__cup">
        {imgCupEmpty}
      </div>
    );
  }

  renderCaption() {
    return (
      <div className="NotFound__caption-container">
        <div className="NotFound__caption-header">
          <FormattedMessage id="not.found.caption.header" />
        </div>
        <div className="NotFound__caption">
          <FormattedMessage id="not.found.caption" />
        </div>
      </div>
    )
  }

  renderButton() {
    return (
      <div className="NotFound__button">
        <Button format="small" color="positive" onClick={this.handleButtonClick}>
          <FormattedMessage id="not.found.button" />
        </Button>
      </div>
    )
  }

  handleButtonClick = () => {
    this.props.history.push(HOME.path);
  }

  render() {
    return (
      <HttpStatusContext.Consumer>
        {(value) => this.renderElement(value)}
      </HttpStatusContext.Consumer>
    );
  }

  private renderElement(value: HttpStatus | undefined) {
    if (value) {
      value.setStatusNotFound();
    }

    return (
      <div className="NotFound__container">
        {this.renderCup()}
        {this.renderCaption()}
        {this.renderButton()}
      </div>
    )
  }
}
