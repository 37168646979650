import { Resolvers } from 'apollo-client';
import { DefaultOptions } from 'apollo-client/ApolloClient';
import { Hub } from 'src/app/pages/StoreLocation/StoreLocation.queries';
import { AddressCache, GET_SELECTED_ADDRESS } from './address';
import { DeliveryLocationCache, GET_SELECTED_DELIVERY_LOCATION } from './deliveryLocation';
import { GET_EXPECTED_COMPLETED_TIME } from './expectedCompletedTime';
import { GET_SELECTED_STORE } from './hub';
import { GET_ORDER_TYPE } from './orderType';
import { GET_PREVIOUS_PATH } from './prevPath';
import { IS_WELCOME_SPLASH_ALREADY_OPEN_QUERY } from './welcomeSplash';

export const defaultOptions: DefaultOptions = {
  mutate: {
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-first',
  },
  watchQuery: {
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
  },
};

export const resolvers: Resolvers = {
  Mutation: {
    openWelcomeSplash: (_, params = {}, { cache }) => {
      const data = { isWelcomeSplashAlreadyOpen: true };
      cache.writeQuery({ query: IS_WELCOME_SPLASH_ALREADY_OPEN_QUERY, data });
      return data.isWelcomeSplashAlreadyOpen;
    },
    setExpectedCompletedTime: (_, { expectedCompletedTime }, { cache }) => {
      const data = {
        expectedCompletedTime,
      };
      cache.writeQuery({ query: GET_EXPECTED_COMPLETED_TIME, data });
      return data.expectedCompletedTime;
    },
    setOrderType: (_, { orderType }, { cache }) => {
      const data = {
        orderType,
      };
      cache.writeQuery({ query: GET_ORDER_TYPE, data });
      return data.orderType;
    },
    setPreviousPath: (_, { path }, { cache }) => {
      const data = {
        prevPath: path,
      };
      cache.writeQuery({ query: GET_PREVIOUS_PATH, data });
      return data.prevPath;
    },
    setSelectedAddress: (
      _,
      { name, address, addressDetail, latitude, longitude, contactPersonName, contactPersonPhoneNo },
      { cache }
    ) => {
      const data = {
        selectedAddress: {
          address,
          addressDetail,
          contactPersonName,
          contactPersonPhoneNo,
          latitude,
          longitude,
          name,
        },
      };
      cache.writeQuery({ query: GET_SELECTED_ADDRESS, data });
      return data.selectedAddress;
    },
    setSelectedDeliveryLocation: (
      _,
      { detail, address, latitude, longitude, contactPersonName, contactPersonPhoneNo, isAddressExist },
      { cache }
    ) => {
      const data = {
        selectedDeliveryLocation: {
          address,
          contactPersonName,
          contactPersonPhoneNo,
          detail,
          isAddressExist,
          latitude,
          longitude,
        },
      };
      cache.writeQuery({ query: GET_SELECTED_DELIVERY_LOCATION, data });
      return data.selectedDeliveryLocation;
    },
    setSelectedStore: (_, { id, name, address, latitude, longitude }, { cache }) => {
      const data = {
        selectedStore: {
          address,
          id,
          latitude,
          longitude,
          name,
        },
      };
      cache.writeQuery({ query: GET_SELECTED_STORE, data });
      return data.selectedStore;
    },
  },
  Query: {
    getExpectedCompletedTime: (_, args, { cache }) => {
      const { expectedCompletedTime } = cache.readQuery({ query: GET_EXPECTED_COMPLETED_TIME });
      return expectedCompletedTime;
    },
    getOrderType: (_, args, { cache }) => {
      const { orderType } = cache.readQuery({ query: GET_ORDER_TYPE });
      return orderType;
    },
    getPrevPath: (_, args, { cache }) => {
      const { prevPath } = cache.readQuery({ query: GET_PREVIOUS_PATH });
      return prevPath;
    },
    getSelectedAddress: (_, args, { cache }) => {
      const { selectedAddress } = cache.readQuery({ query: GET_SELECTED_ADDRESS });
      return selectedAddress;
    },
    getSelectedDeliveryLocation: (_, args, { cache }) => {
      const { selectedRecentLocation } = cache.readQuery({ query: GET_SELECTED_DELIVERY_LOCATION });
      return selectedRecentLocation;
    },
    getSelectedHub: (_, args, { cache }) => {
      const { selectedStore } = cache.readQuery({ query: GET_SELECTED_STORE });
      return selectedStore;
    },
    isWelcomeSplashAlreadyOpen: (_, args, { cache }) => {
      const { isWelcomeSplashAlreadyOpen } = cache.readQuery({ query: IS_WELCOME_SPLASH_ALREADY_OPEN_QUERY });
      return isWelcomeSplashAlreadyOpen;
    },
  },
};

export interface ApolloState {
  data: {
    expectedCompletedTime: string;
    isWelcomeSplashAlreadyOpen: boolean;
    selectedAddress: AddressCache;
    selectedDeliveryLocation: DeliveryLocationCache;
    selectedStore: Hub;
    prevPath: string;
    orderType: string;
  };
}

export const initialData: ApolloState = {
  data: {
    expectedCompletedTime: '',
    isWelcomeSplashAlreadyOpen: false,
    orderType: '',
    prevPath: '',
    selectedAddress: {
      __typename: 'Address',
      address: '',
      addressDetail: '',
      contactPersonName: '',
      contactPersonPhoneNo: '',
      latitude: 0,
      longitude: 0,
      name: '',
    },
    selectedDeliveryLocation: {
      __typename: 'DeliveryLocation',
      address: '',
      contactPersonName: '',
      contactPersonPhoneNo: '',
      detail: '',
      latitude: 0,
      longitude: 0,
    },
    selectedStore: {
      __typename: 'Hub',
      address: '',
      id: '',
      latitude: 0,
      longitude: 0,
      name: '',
    },
  },
};
