import * as React from 'react';

import { Loadable } from '@gdp/react-app/lib/helpers/loadable';
import { withStyles } from '@gdp/react-app/lib/helpers/withStyles';
import { Route, Switch } from 'react-router-dom';
import { NotFound } from 'src/app/components/NotFound';
import {
  ABOUT_US,
  ACCOUNT_SETTING,
  CHECKOUT,
  CONTACT,
  DEAL,
  DELIVERY_LOCATION,
  ESPAY,
  FAQ,
  HOME,
  HUB_CHECKOUT,
  HUB_MENU,
  HUB_ORDER_DETAIL,
  HUB_PAYMENT,
  LOGIN,
  MACHINE_CHECKOUT,
  MACHINE_MENU,
  MACHINE_ORDER_DETAIL,
  MACHINE_PAYMENT,
  MACHINE_TYPE_DISPLAY,
  MEMBERSHIP,
  NOTIFICATION,
  NOTIFICATION_DETAIL,
  ONGOING_ORDER,
  ORDER_DESTINATION,
  ORDER_HISTORY,
  ORDER_TRACKING,
  PROMO,
  REWARD_ORDER_DETAIL,
  REWARD_PAYMENT,
  SCAN_QR,
  STORE_LOCATION,
  VOUCHER
} from 'src/app/constants/route';
import { routeMapperFn } from 'src/app/utils/RouteUtil';

const routes = [
  {
    component: Loadable(
      '../../pages/AboutUs/AboutUsPage',
      () => import(/* webpackChunkName: "AboutUsPage" */ '../../pages/AboutUs/AboutUsPage'),
      () => [(require as any).resolveWeak('../../pages/AboutUs/AboutUsPage')]
    ),
    isExact: true,
    path: ABOUT_US.path
  },
  {
    component: Loadable(
      '../../pages/OrderDestination/OrderDestinationPage',
      () => import(/* webpackChunkName: "OrderDestination" */ '../../pages/OrderDestination/OrderDestinationPage'),
      () => [(require as any).resolveWeak('../../pages/OrderDestination/OrderDestinationPage')]
    ),
    isExact: false,
    path: ORDER_DESTINATION.path
  },
  {
    component: Loadable(
      '../../pages/MachineTypeDisplay/MachineTypeDisplayPage',
      () =>
        import(/* webpackChunkName: "MachineTypeDisplay" */ '../../pages/MachineTypeDisplay/MachineTypeDisplayPage'),
      () => [(require as any).resolveWeak('../../pages/MachineTypeDisplay/MachineTypeDisplayPage')]
    ),
    isExact: true,
    path: MACHINE_TYPE_DISPLAY.path
  },
  {
    component: Loadable(
      '../../pages/Menu/MenuRoutes',
      () => import(/* webpackChunkName: "MenuRoutes" */ '../../pages/Menu/MenuRoutes'),
      () => [(require as any).resolveWeak('../../pages/Menu/MenuRoutes')]
    ),
    isExact: false,
    path: HUB_MENU.path
  },
  {
    component: Loadable(
      '../../pages/Product/ProductRoutes',
      () => import(/* webpackChunkName: "ProductRoutes" */ '../../pages/Product/ProductRoutes'),
      () => [(require as any).resolveWeak('../../pages/Product/ProductRoutes')]
    ),
    isExact: false,
    path: MACHINE_MENU.path
  },
  {
    component: Loadable(
      '../../pages/OrderList/Ongoing/OngoingOrderPage',
      () => import(/* webpackChunkName: "OngoingOrderPage" */ '../../pages/OrderList/Ongoing/OngoingOrderPage'),
      () => [(require as any).resolveWeak('../../pages/OrderList/Ongoing/OngoingOrderPage')]
    ),
    isExact: true,
    path: ONGOING_ORDER.path
  },
  {
    component: Loadable(
      '../../pages/OrderList/History/OrderHistoryPage',
      () => import(/* webpackChunkName: "OrderHistoryPage" */ '../../pages/OrderList/History/OrderHistoryPage'),
      () => [(require as any).resolveWeak('../../pages/OrderList/History/OrderHistoryPage')]
    ),
    isExact: true,
    path: ORDER_HISTORY.path
  },
  {
    component: Loadable(
      '../../pages/OrderDetail/Machine/MachineOrderDetailPage',
      () =>
        import(
          /* webpackChunkName: "MachineOrderDetailPage" */ '../../pages/OrderDetail/Machine/MachineOrderDetailPage'
        ),
      () => [(require as any).resolveWeak('../../pages/OrderDetail/Machine/MachineOrderDetailPage')]
    ),
    isExact: true,
    path: MACHINE_ORDER_DETAIL.path
  },
  {
    component: Loadable(
      '../../pages/OrderDetail/Hub/HubOrderDetailPage',
      () => import(/* webpackChunkName: "HubOrderDetailPage" */ '../../pages/OrderDetail/Hub/HubOrderDetailPage'),
      () => [(require as any).resolveWeak('../../pages/OrderDetail/Hub/HubOrderDetailPage')]
    ),
    isExact: true,
    path: HUB_ORDER_DETAIL.path
  },
  {
    component: Loadable(
      '../../pages/OrderDetail/Reward/RewardOrderDetailPage',
      () =>
        import(/* webpackChunkName: "RewardOrderDetailPage" */ '../../pages/OrderDetail/Reward/RewardOrderDetailPage'),
      () => [(require as any).resolveWeak('../../pages/OrderDetail/Reward/RewardOrderDetailPage')]
    ),
    isExact: true,
    path: REWARD_ORDER_DETAIL.path
  },
  {
    component: Loadable(
      '../../pages/OrderTracking/OrderTrackingPage',
      () => import(/* webpackChunkName: "OrderTrackingPage" */ '../../pages/OrderTracking/OrderTrackingPage'),
      () => [(require as any).resolveWeak('../../pages/OrderTracking/OrderTrackingPage')]
    ),
    isExact: true,
    path: ORDER_TRACKING.path
  },
  {
    component: Loadable(
      '../../pages/AccountSetting/AccountSettingPage',
      () => import(/* webpackChunkName: "AccountSettingPage" */ '../../pages/AccountSetting/AccountSettingPage'),
      () => [(require as any).resolveWeak('../../pages/AccountSetting/AccountSettingPage')]
    ),
    isExact: true,
    path: ACCOUNT_SETTING.path
  },
  {
    component: Loadable(
      '../../containers/Faq/FaqRoutes',
      () => import(/* webpackChunkName: "FaqRoutes" */ '../../containers/Faq/FaqRoutes'),
      () => [(require as any).resolveWeak('../../containers/Faq/FaqRoutes')]
    ),
    isExact: true,
    path: FAQ.path
  },
  {
    component: Loadable(
      '../../containers/Contact/ContactRoutes',
      () => import(/* webpackChunkName: "ContactRoutes" */ '../../containers/Contact/ContactRoutes'),
      () => [(require as any).resolveWeak('../../containers/Contact/ContactRoutes')]
    ),
    isExact: true,
    path: CONTACT.path
  },
  {
    component: Loadable(
      '../../containers/Login',
      () => import(/* webpackChunkName: "Login" */ '../../containers/Login'),
      () => [(require as any).resolveWeak('../../containers/Login')]
    ),
    isExact: true,
    path: LOGIN.path
  },
  {
    component: Loadable(
      '../../pages/Home/HomePage',
      () => import(/* webpackChunkName: "HomePage" */ '../../pages/Home/HomePage'),
      () => [(require as any).resolveWeak('../../pages/Home/HomePage')]
    ),
    isExact: true,
    path: HOME.path
  },
  {
    component: Loadable(
      '../../pages/Checkout/CheckoutRoutes',
      () => import(/* webpackChunkName: "CheckoutRoutes" */ '../../pages/Checkout/CheckoutRoutes'),
      () => [(require as any).resolveWeak('../../pages/Checkout/CheckoutRoutes')]
    ),
    isExact: false,
    path: CHECKOUT.path
  },
  {
    component: Loadable(
      '../../pages/Checkout/CheckoutPage',
      () => import(/* webpackChunkName: "CheckoutPage" */ '../../pages/Checkout/CheckoutPage'),
      () => [(require as any).resolveWeak('../../pages/Checkout/CheckoutPage')]
    ),
    isExact: true,
    path: HUB_CHECKOUT.path
  },
  {
    component: Loadable(
      '../../pages/CheckoutProduct/CheckoutProductPage',
      () => import(/* webpackChunkName: "CheckoutProductPage" */ '../../pages/CheckoutProduct/CheckoutProductPage'),
      () => [(require as any).resolveWeak('../../pages/CheckoutProduct/CheckoutProductPage')]
    ),
    isExact: true,
    path: MACHINE_CHECKOUT.path
  },
  {
    component: Loadable(
      '../../pages/StoreLocation/StoreLocationRoutes',
      () => import(/* webpackChunkName: "StoreLocationRoutes" */ '../../pages/StoreLocation/StoreLocationRoutes'),
      () => [(require as any).resolveWeak('../../pages/StoreLocation/StoreLocationRoutes')]
    ),
    isExact: true,
    path: STORE_LOCATION.path
  },
  {
    component: Loadable(
      '../../pages/DeliveryLocation/DeliveryLocationRoutes',
      () =>
        import(/* webpackChunkName: "DeliveryLocationRoutes" */ '../../pages/DeliveryLocation/DeliveryLocationRoutes'),
      () => [(require as any).resolveWeak('../../pages/DeliveryLocation/DeliveryLocationRoutes')]
    ),
    isExact: false,
    path: DELIVERY_LOCATION.path
  },
  {
    component: Loadable(
      '../../pages/Deal/VoucherList/VoucherListRoutes',
      () => import(/* webpackChunkName: "VoucherListRoutes" */ '../../pages/Deal/VoucherList/VoucherListRoutes'),
      () => [(require as any).resolveWeak('../../pages/Deal/VoucherList/VoucherListRoutes')]
    ),
    isExact: false,
    path: VOUCHER.path
  },
  {
    component: Loadable(
      '../../pages/Deal/DealRoutes',
      () => import(/* webpackChunkName: "DealRoutes" */ '../../pages/Deal/DealRoutes'),
      () => [(require as any).resolveWeak('../../pages/Deal/DealRoutes')]
    ),
    isExact: false,
    path: PROMO.path
  },
  {
    component: Loadable(
      '../../pages/Payment/PaymentHubOrderPage',
      () => import(/* webpackChunkName: "PaymentHubOrderPage" */ '../../pages/Payment/PaymentHubOrderPage'),
      () => [(require as any).resolveWeak('../../pages/Payment/PaymentHubOrderPage')]
    ),
    isExact: false,
    path: HUB_PAYMENT.path
  },
  {
    component: Loadable(
      '../../pages/Payment/PaymentMachineOrderPage',
      () => import(/* webpackChunkName: "PaymentMachineOrderPage" */ '../../pages/Payment/PaymentMachineOrderPage'),
      () => [(require as any).resolveWeak('../../pages/Payment/PaymentMachineOrderPage')]
    ),
    isExact: false,
    path: MACHINE_PAYMENT.path
  },
  {
    component: Loadable(
      '../../containers/Espay/EspayRoutes',
      () => import(/* webpackChunkName: "EspayRoutes" */ '../../containers/Espay/EspayRoutes'),
      () => [(require as any).resolveWeak('../../containers/Espay/EspayRoutes')]
    ),
    isExact: true,
    path: ESPAY.path
  },
  {
    component: Loadable(
      '../../containers/ScanQr/ScanQrRoutes',
      () => import(/* webpackChunkName: "ScanQrRoutes" */ '../../containers/ScanQr/ScanQrRoutes'),
      () => [(require as any).resolveWeak('../../containers/ScanQr/ScanQrRoutes')]
    ),
    isExact: false,
    path: SCAN_QR.path
  },
  {
    component: Loadable(
      '../../pages/QRDeal/QRDealRoutes',
      () => import(/* webpackChunkName: "DealRoutes" */ '../../pages/QRDeal/QRDealRoutes'),
      () => [(require as any).resolveWeak('../../pages/QRDeal/QRDealRoutes')]
    ),
    isExact: false,
    path: DEAL.path
  },
  {
    component: Loadable(
      '../../pages/NotificationList/NotificationListPage',
      () => import(/* webpackChunkName: "NotificationListPage" */ '../../pages/NotificationList/NotificationListPage'),
      () => [(require as any).resolveWeak('../../pages/NotificationList/NotificationListPage')]
    ),
    isExact: true,
    path: NOTIFICATION.path
  },
  {
    component: Loadable(
      '../../pages/NotificationDetail/NotificationDetailPage',
      () =>
        import(
          /* webpackChunkName: "NotificationDetailPage" */ '../../pages/NotificationDetail/NotificationDetailPage'
        ),
      () => [(require as any).resolveWeak('../../pages/NotificationDetail/NotificationDetailPage')]
    ),
    isExact: true,
    path: NOTIFICATION_DETAIL.path
  },
  {
    component: Loadable(
      '../../pages/Membership/MembershipRoutes',
      () => import(/* webpackChunkName: "MembershipRoutes" */ '../../pages/Membership/MembershipRoutes'),
      () => [(require as any).resolveWeak('../../pages/Membership/MembershipRoutes')]
    ),
    isExact: false,
    path: MEMBERSHIP.path
  },
  {
    component: Loadable(
      '../../pages/RewardPayment/RewardPaymentPage',
      () => import(/* webpackChunkName: "RewardPaymentPage" */ '../../pages/RewardPayment/RewardPaymentPage'),
      () => [(require as any).resolveWeak('../../pages/RewardPayment/RewardPaymentPage')]
    ),
    isExact: true,
    path: REWARD_PAYMENT.path
  }
];

@withStyles(require('./Routes.scss'))
export class Routes extends React.Component {
  private resizeTimeout: any;

  componentDidMount(): void {
    this.updateViewportHeight();
    const resizeEventString = 'resize';
    const resizeEventFnDelayMs = 100;
    window.addEventListener(resizeEventString, () => {
      window.clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.updateViewportHeight();
      }, resizeEventFnDelayMs);
    });
  }

  componentWillUnmount(): void {
    window.clearTimeout(this.resizeTimeout);
  }

  updateViewportHeight = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  render() {
    return (
      <Switch>
        {routes.map(routeMapperFn)}
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
