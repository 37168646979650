import gql from 'graphql-tag';

export const GET_ORDER_TYPE = gql`
  query GetOrderType {
    orderType @client
  }
`;

export interface FetchOrderTypeResult {
  orderType: string;
}

export const SET_ORDER_TYPE = gql`
  mutation SetOrderType($orderType: String!) {
    setOrderType(orderType: $orderType) @client
  }
`;

export interface OrderTypeVariables {
  orderType: string;
}
