import { Theme } from '@material-ui/core';
import React from 'react';
import { getTheme } from 'src/styles/theme';

export interface AssetCustomizationData {
  cartIconUrl: string;
  emptyState: {
    orderIconUrl: string;
  };
  logoUrl: string;
  loyaltyPointIconUrl: string;
  membership: {
    progressDarkIconUrl: string;
    progressLightIconUrl: string;
  };
  menuIconUrl: string;
  socialMedia: {
    facebookUrl?: string;
    instagramUrl?: string;
    linkedInUrl?: string;
    tikTokUrl?: string;
  };
  welcomeSplash: { pngUrl: string; webpUrl?: string };
}
export const ASSETS: AssetCustomizationData = {
  cartIconUrl: '/assets/paper-bag_1612932791.png',
  emptyState: { orderIconUrl: '/assets/order-empty.png' },
  logoUrl: '/assets/jumpstart-logo.png',
  loyaltyPointIconUrl: '/assets/loyalty-point.png',
  membership: {
    progressDarkIconUrl: '/assets/cup-primary-dark.png',
    progressLightIconUrl: '/assets/cup-primary-light.png'
  },
  menuIconUrl: '/assets/cup-black.png',
  socialMedia: {
    facebookUrl: 'https://www.facebook.com/WeJumpStartYourDay',
    instagramUrl: 'https://www.instagram.com/jumpstart_indonesia/',
    linkedInUrl: 'https://www.linkedin.com/company/jumpstart-coffee',
    tikTokUrl: 'https://www.tiktok.com/@coffeejumpstart'
  },
  welcomeSplash: {
    pngUrl: '/assets/welcome-background_1605847822.png',
    webpUrl: '/assets/welcome-background_1605847822.webp'
  }
};

export interface ThemeCustomizationData {
  color: {
    info: string;
    primary: string;
    primaryDark: string;
    primaryLight: string;
    secondary: string;
    secondaryDark?: string;
    secondaryLight?: string;
    textPrimary?: string;
    textSecondary?: string;
    error?: string;
    success?: string;
  };
}
const THEME_CUSTOMIZATION: ThemeCustomizationData = {
  color: {
    info: 'rgba(255, 218, 41, 0.1)',
    primary: '#ffda29',
    primaryDark: '#ccae20',
    primaryLight: '#ffe569',
    secondary: '#ffffff'
  }
};
export const THEME = getTheme(THEME_CUSTOMIZATION);

export interface CustomizationContextData {
  assets: AssetCustomizationData;
  theme: Theme;
}
export const CustomizationContext = React.createContext<CustomizationContextData>({ assets: ASSETS, theme: THEME });
