import gql from 'graphql-tag';

export const GET_EXPECTED_COMPLETED_TIME = gql`
  query GetExpectedCompletedTime {
    expectedCompletedTime @client
  }
`;

export interface FetchExpectedCompletedTimeResult {
  expectedCompletedTime: string;
}

export const SET_EXPECTED_COMPLETED_TIME = gql`
  mutation SetExpectedCompletedTime($expectedCompletedTime: Date!) {
    setExpectedCompletedTime(expectedCompletedTime: $expectedCompletedTime) @client
  }
`;

export interface ExpectedCompletedTimeVariables {
  expectedCompletedTime: Date;
}
