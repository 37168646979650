export const COLOR_BLACK = '#151515';
export const COLOR_BLUE = '#1a5fcd';
export const COLOR_BROWN = '#593823';
export const COLOR_ORANGE = '#faa61a';
export const COLOR_GOLD = '#dcc077';
export const COLOR_GREEN = '#1e9f2d';
export const COLOR_PURE_BLACK = '#000000';
export const COLOR_GRAY = '#707070';
export const COLOR_GRAY_LIGHT = '#d1d1d1';
export const COLOR_RED = '#ff0000';
export const COLOR_SILVER = '#c0c0c0';
export const COLOR_WHITE = '#ffffff';

export const COLOR_ERROR = COLOR_RED;
export const COLOR_SUCCESS = '#27ae60';

export const RGBA_DARK_GREY = 'rgba(0, 0, 0, 0.5)';
export const RGBA_GREY = 'rgba(0, 0, 0, 0.35)';
export const RGBA_LIGHT_GREY = 'rgba(0, 0, 0, 0.05)';
export const RGBA_LIGHT_RED = 'rgba(255, 0, 0, 0.05)';

export const ZINDEX_HEADER = 1100;
export const ZINDEX_DRAWER = 1200;
export const ZINDEX_MODAL = 1300;
export const ZINDEX_SNACKBAR = 1400;
export const ZINDEX_TOOLTIP = 1500;

export const NEW_LINE_FORMATTED_MESSAGE = '{newLine}';
export const REFERRAL_CODE_NOT_FOUND_MESSAGE = 'Referral code not found';
