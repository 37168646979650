import gql from 'graphql-tag';

export const IS_WELCOME_SPLASH_ALREADY_OPEN_QUERY = gql`
  query IsWelcomeSplashAlreadyOpen {
    isWelcomeSplashAlreadyOpen @client
  }
`;

export const OPEN_WELCOME_SPLASH_MUTATION = gql`
  mutation OpenWelcomeSplash {
    openWelcomeSplash @client
  }
`;
