import * as React from 'react';

import { Route } from 'react-router';

export const routeMapperFn = (route: any, index: number) => (
  <Route key={index} path={route.path} exact={route.isExact} component={route.component} />
);

export const transformToUrlSlug = (phrase: string) =>
  phrase
    .split(' ')
    .map(word => word.toLowerCase())
    .join('-');
